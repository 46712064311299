@font-face {
  font-family: "Quicksand";
  src: url(../assets/fonts/Quicksand-Regular.ttf);
}

@font-face {
  font-family: "Manrope";
  src: url(../assets/fonts/Manrope-Regular.ttf);
}
@font-face {
  font-family: "ManropeBold";
  src: url(../assets/fonts/Manrope-Bold.ttf);
}
@font-face {
  font-family: "ManropeExtraLight";
  src: url(../assets/fonts/Manrope-ExtraLight.ttf);
}


body {
  font-family: "Manrope";
  background-color: #fff;
}

label {
  font-family: "Manrope";
  font-weight: bold;
  font-size: 14px;
}

input {
  border: none;

  &:focus {
    outline: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  // -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}